import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import { quantum } from 'ldrs'; // uiball yükleme animasyonu
quantum.register();


const ProductEditModal = ({ productId, showModal, setShowModal, onProductUpdate }) => {
  const { user } = useAuth();
  const [product, setProduct] = useState(null);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [tags, setTags] = useState('');
  const [baseImage, setBaseImage] = useState('');
  const [variationProfile, setVariationProfile] = useState('');
  const [newImage, setNewImage] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [variationProfiles, setVariationProfiles] = useState([]);
  const [mockupImages, setMockupImages] = useState([]);
  const [isGeneratingMockup, setIsGeneratingMockup] = useState(false);
  const [userMockupImages, setUserMockupImages] = useState([]); // Kullanıcının yüklediği resimler
  const [sections, setSections] = useState([]);

  useEffect(() => {
    if (productId && showModal) {
      const fetchProduct = async () => {
        try {
          const token = localStorage.getItem('token');
          if (!token) {
            throw new Error('No token found');
          }
          const response = await axios.get(`https://etsyhelper.onrender.com/products/${productId}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          const { name, description, tags, base_image, variation_profile, images, user_mockups, section_id  } = response.data;
          setProduct(response.data);
          setName(name || '');
          setDescription(description || '');
          setTags(tags || '');
          setBaseImage(base_image || '');
          setVariationProfile(variation_profile || '');
          setMockupImages(images ? images.split(',') : []); // Backend'den gelen mockup resimlerini ayır
          setUserMockupImages(user_mockups ? user_mockups.split(',') : []);
          // Section bilgisi var ise set ediliyor
          if (section_id) {
            setProduct((prevProduct) => ({
              ...prevProduct,
              section: section_id  // Section ID'yi ürün state'ine kaydediyoruz
            }));
          }
        } catch (error) {
          console.error('Error fetching product:', error);
          setError('Failed to fetch product');
        } finally {
          setLoading(false);
        }
      };
      fetchProduct();
      fetchVariationProfiles();
    }
  }, [productId, showModal]);

  const fetchVariationProfiles = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }
      const response = await axios.get('https://etsyhelper.onrender.com/variation_profiles', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setVariationProfiles(response.data);
    } catch (error) {
      console.error('Error fetching variation profiles:', error);
    }
  };

    // store_sections tablosundaki sectionları fetch etmek için yeni useEffect hook
  useEffect(() => {
    const fetchSections = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found');
        }

        // store_sections tablosundan sectionları çekiyoruz
        const response = await axios.get('https://etsyhelper.onrender.com/store_sections', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setSections(response.data); // Gelen sectionları set ediyoruz
      } catch (error) {
        console.error('Error fetching store sections:', error);
      }
    };

    fetchSections();
  }, []);
  

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setBaseImage(reader.result);
    };
    reader.readAsDataURL(file);
    setNewImage(file);
  };

// Dropzone configurasyonları
const { getRootProps, getInputProps } = useDropzone({
  accept: 'image/*',
  onDrop: (acceptedFiles) => {
    setUserMockupImages(prevState => [
      ...prevState,
      ...acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }))
    ]);
  }
});

const handleSubmit = async (e) => {
  e.preventDefault();
  const formData = new FormData();
  formData.append('name', name);
  formData.append('description', description);
  formData.append('tags', tags);
  formData.append('variation_profile', variationProfile);
  formData.append('section_id', product?.section_id || '');
  
  // User tarafından eklenen mockup resimlerini backend'e gönderme
  userMockupImages.forEach(file => {
    if (file instanceof File) {
      formData.append('user_mockups', file);
    }
  });

  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('No token found');
    }
    await axios.put(`https://etsyhelper.onrender.com/products/${productId}`, formData, {
      headers: { 
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    toast.success('Product updated successfully');
    onProductUpdate();
    setShowModal(false);
  } catch (error) {
    console.error('Error updating product:', error);
    setError('Failed to update product');
  }
};


  const handleGenerateMockup = async () => {
    setIsGeneratingMockup(true);
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      if (mockupImages.length > 0) {
        await axios.post('https://etsyhelper.onrender.com/delete_mockups', { product_id: productId }, {
          headers: { Authorization: `Bearer ${token}` },
        });
      }
      await axios.post('https://etsyhelper.onrender.com/generate_mockup', { product_id: productId }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success('Mockup generated successfully');
      onProductUpdate();
    } catch (error) {
      console.error('Error generating mockup:', error);
      toast.error('Failed to generate mockup');
    } finally {
      setIsGeneratingMockup(false);
      setLoading(false);
    }
  };

  if (!showModal) {
    return null;
  }

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <h1 className="text-2xl font-bold text-center mb-4">Edit Product</h1>
            {loading && (
              <div className="absolute inset-0 bg-gray-100 bg-opacity-50 flex justify-center items-center z-50">
                <l-quantum size="45" speed="1.75" color="black"></l-quantum>
              </div>
            )}
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="mt-1 block w-full px-3 py-2 bg-gray-100 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-purple-500"
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                    Description
                  </label>
                  <textarea
                    name="description"
                    id="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="mt-1 block w-full px-3 py-2 bg-gray-100 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-purple-500"
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="tags" className="block text-sm font-medium text-gray-700">
                    Tags
                  </label>
                  <input
                    type="text"
                    name="tags"
                    id="tags"
                    value={tags}
                    onChange={(e) => setTags(e.target.value)}
                    className="mt-1 block w-full px-3 py-2 bg-gray-100 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-purple-500"
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="base_image" className="block text-sm font-medium text-gray-700">
                    Base Image
                  </label>
                  <div className="flex items-center">
                    <img src={baseImage} alt="Base" className="w-24 h-24 object-contain rounded-md mr-4" />
                    <input
                      type="file"
                      name="base_image"
                      id="base_image"
                      accept="image/*"
                      onChange={handleImageChange}
                      className="hidden"
                    />
                    <label
                      htmlFor="base_image"
                      className="cursor-pointer bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
                    >
                      Change
                    </label>
                  </div>
                </div>
                <div className="mb-4">
                  <label htmlFor="variation_profile" className="block text-sm font-medium text-gray-700">
                    Variation Profile
                  </label>
                  <select
                    name="variation_profile"
                    id="variation_profile"
                    value={variationProfile}
                    onChange={(e) => setVariationProfile(e.target.value)}
                    className="mt-1 block w-full px-3 py-2 bg-gray-100 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-purple-500"
                  >
                    <option value="" disabled>Select a profile</option>
                    {variationProfiles.map((profile) => (
                      <option key={profile.name} value={profile.name}>
                        {profile.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-4">
                  <label htmlFor="section" className="block text-sm font-medium text-gray-700">
                    Select Section
                  </label>
                  <select
                    name="section"
                    id="section"
                    className="mt-1 block w-full px-3 py-2 bg-gray-100 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-purple-500"
                    value={product?.section_id || ''}  // Eğer product section içeriyorsa seçili gösteriyoruz
                    onChange={(e) => setProduct({ ...product, section_id: e.target.value })}  // Section'ı state'e kaydediyoruz
                  >
                    <option value="" disabled>Select a section</option>
                    {sections.map((section) => (
                      <option key={section.id} value={section.id}>
                        {section.title}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Kendi Mockup Resimlerinizi Buraya Yükleyebilirsiniz.
                  </label>
                  <div {...getRootProps({ className: 'dropzone' })} className="border-dashed border-2 border-gray-300 rounded-md p-4">
                    <input {...getInputProps()} />
                    <p>Drag 'n' drop some files here, or click to select files</p>
                  </div>
                  <div className="grid grid-cols-3 gap-4 mt-2">
                    {userMockupImages.map((image, index) => (
                      <img key={index} src={image} alt={`Mockup ${index + 1}`} className="w-24 h-24 object-contain rounded-md" />
                    ))}
                  </div>
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">Mockup Images</label>
                  <div className="grid grid-cols-3 gap-4 mt-2">
                    {mockupImages.map((image, index) => (
                      <img key={index} src={image} alt={`Mockup ${index + 1}`} className="w-24 h-24 object-contain rounded-md" />
                    ))}
                  </div>
                </div>
                {error && <div className="text-red-500 mb-4">{error}</div>}
                <div className="flex justify-end">
                  <button
                    type="button"
                    className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded mr-2"
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="bg-purple-500 hover:bg-purple-600 text-white font-bold py-2 px-4 rounded mr-2"
                    onClick={handleGenerateMockup}
                    disabled={isGeneratingMockup}
                  >
                    {isGeneratingMockup ? 'Generating...' : mockupImages.length > 0 ? 'Regenerate Mockup' : 'Generate Mockup'}
                  </button>
                  <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                  >
                    Save
                  </button>
                </div>
              </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductEditModal;
