import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ModalImage from 'react-modal-image';  // react-modal-image kullanımı

const ProductList = () => {
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const productsPerPage = 10;

    useEffect(() => {
        const token = localStorage.getItem('token');
        axios.get('https://etsyhelper.onrender.com/api/ready-products', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            const products = response.data;
            if (Array.isArray(products)) {
                setProducts(products);
            } else {
                console.error("Error: Expected an array but got something else.", products);
                setProducts([]);
            }
        })
        .catch(error => {
            console.error("Error fetching products:", error);
            setProducts([]);
        });
    }, []);

    const handlePublishProduct = (productId) => {
        const token = localStorage.getItem('token');
        axios.post(`https://etsyhelper.onrender.com/api/publish-product/${productId}`, 
            { product_id: productId },  // Product ID'yi body içinde gönderiyoruz
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
        )
        .then(() => {
            alert(`Product ${productId} published successfully!`);
            setProducts(products.filter(product => product.id !== productId));
        })
        .catch(error => {
            console.error("Error publishing product:", error);
            alert("Failed to publish product.");
        });
    };

    const handlePublishSelected = () => {
        const token = localStorage.getItem('token');
        selectedProducts.forEach(productId => {
            axios.post(`https://etsyhelper.onrender.com/api/publish-product/${productId}`, {}, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(() => {
                alert(`Product ${productId} published successfully!`);
                setProducts(products.filter(product => product.id !== productId));
            })
            .catch(error => {
                console.error("Error publishing product:", error);
                alert("Failed to publish product.");
            });
        });
    };

    const handleSelectProduct = (productId) => {
        setSelectedProducts(prevSelected => 
            prevSelected.includes(productId)
                ? prevSelected.filter(id => id !== productId)
                : [...prevSelected, productId]
        );
    };

    const handleSelectAll = () => {
        if (selectedProducts.length === paginatedProducts.length) {
            setSelectedProducts([]);  // Hepsini seçimi kaldır
        } else {
            setSelectedProducts(paginatedProducts.map(product => product.id)); // Hepsini seç
        }
    };

    // Pagination (sayfalama) işlemleri
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const paginatedProducts = products.slice(indexOfFirstProduct, indexOfLastProduct);

    const totalPages = Math.ceil(products.length / productsPerPage);

    return (
        <div className="container mx-auto p-6">
            <h1 className="text-2xl font-bold mb-4">Ready to Publish Products</h1>
            {paginatedProducts.length > 0 ? (
                <>
                    <div className="mb-4 flex items-center">
                        <input
                            type="checkbox"
                            onChange={handleSelectAll}
                            checked={selectedProducts.length === paginatedProducts.length}
                            className="mr-2"
                        />
                        <label>Select All</label>
                        {selectedProducts.length > 0 && (
                            <button
                                onClick={handlePublishSelected}
                                className="ml-auto bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                            >
                                Publish Selected
                            </button>
                        )}
                    </div>
                    <ul id="product-list" className="space-y-4">
                        {paginatedProducts.map((product) => (
                            <li key={product.id} className="bg-white shadow rounded-lg overflow-hidden flex items-center space-x-4 p-4">
                                <input
                                    type="checkbox"
                                    checked={selectedProducts.includes(product.id)}
                                    onChange={() => handleSelectProduct(product.id)}
                                    className="mr-4"
                                />
                                {/* ModalImage ile resim gösterimi */}
                                <ModalImage
                                    small={product.base_image}
                                    large={product.base_image}
                                    alt=''
                                    className="w-32 h-32 object-cover rounded-md cursor-pointer"
                                />
                                <div className="flex-grow">
                                    <h3 className="text-lg font-semibold">{product.name}</h3>
                                    <p className="text-gray-600">Profile: {product.variation_profile}</p>
                                    <p className="text-gray-600">Section: {product.section_title}</p>
                                </div>
                                <button
                                    onClick={() => handlePublishProduct(product.id)} // Tek tek ürün gönderme butonu
                                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 ml-4 md:ml-0"
                                >
                                    Publish
                                </button>
                            </li>
                        ))}
                    </ul>

                    {/* Pagination */}
                    <div className="flex justify-center mt-4">
                        {Array.from({ length: totalPages }, (_, index) => (
                            <button
                                key={index}
                                onClick={() => setCurrentPage(index + 1)}
                                className={`px-4 py-2 mx-1 rounded ${currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'}`}
                            >
                                {index + 1}
                            </button>
                        ))}
                    </div>
                </>
            ) : (
                <p>No products ready for publishing.</p>
            )}
        </div>
    );
};

export default ProductList;
