import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FaHome, FaCheck, FaHeadset, FaUser, FaFile, FaStore, FaSignOutAlt, FaFilter } from 'react-icons/fa';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import axios from 'axios'; // Veriyi çekmek için axios kullanıyoruz
import { useAuth } from '../context/AuthContext';
import { FaArrowsRotate, FaBoxesStacked, FaListCheck } from 'react-icons/fa6';

const MySidebar = ({ sidebarOpen, toggleSidebar }) => {  // Prop olarak sidebarOpen ve toggleSidebar alıyoruz
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [userData, setUserData] = useState({
    first_name: '',
    last_name: '',
    email: '',
  });

  const navigate = useNavigate();
  const { logout } = useAuth();  // isAuthenticated'a artık gerek yok, çünkü App.js'de kontrol ediliyor

  const toggleUserMenu = () => {
    setIsUserMenuOpen(!isUserMenuOpen);
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  useEffect(() => {
    // API'den kullanıcı verisini çekiyoruz
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('https://etsyhelper.onrender.com/user', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setUserData(response.data);
      } catch (error) {
        console.error('Kullanıcı verisi alınırken hata oluştu:', error);
      }
    };
    fetchUserData();
  }, []);

  return (
    <div className={`fixed top-0 left-0 h-screen bg-[#f5f6fa] w-64 p-4 shadow-lg flex flex-col justify-between transform transition-transform duration-300 ${sidebarOpen ? 'translate-x-0' : '-translate-x-full'}`}>
      {/* Logo */}
      <div className="text-center mb-4">
        <h1 className="text-[#7b2cbf] font-bold text-lg">Canvas Helper</h1>
      </div>

      {/* Menu */}
      <nav>
        <div className="mb-3">
          <NavLink
            to="/"
            className={({ isActive }) =>
              `flex items-center p-2 rounded-lg transition-colors ${isActive ? 'bg-[#7b2cbf] text-white' : 'text-gray-700 hover:bg-gray-100'}`
            }
            onClick={toggleSidebar}  // Sidebar linklere tıklandığında kapanacak
          >
            <FaHome className="mr-2" />
            Ana Sayfa
          </NavLink>
        </div>

        <div className="mb-3">
          <span className="text-gray-700 font-semibold">Ürünler</span>
          <div className="ml-1 mt-1">
            <div className="mb-1">
              <NavLink
                to="/prepared-listings"
                className={({ isActive }) =>
                  `flex items-center p-2 rounded-lg transition-colors ${isActive ? 'bg-[#7b2cbf] text-white' : 'text-gray-700 hover:bg-gray-100'}`
                }
                onClick={toggleSidebar}
              >
                <FaCheck className="mr-2" />
                Hazır Ürünler
              </NavLink>
            </div>
            <div className="mb-1">
              <NavLink
                to="/all-products"
                className={({ isActive }) =>
                  `flex items-center p-2 rounded-lg transition-colors ${isActive ? 'bg-[#7b2cbf] text-white' : 'text-gray-700 hover:bg-gray-100'}`
                }
                onClick={toggleSidebar}
              >
                <FaListCheck className="mr-2" />
                Incomplete Products
              </NavLink>
            </div>
            <div className="mb-1">
              <NavLink
                to="/bulk-upload"
                className={({ isActive }) =>
                  `flex items-center p-2 rounded-lg transition-colors ${isActive ? 'bg-[#7b2cbf] text-white' : 'text-gray-700 hover:bg-gray-100'}`
                }
                onClick={toggleSidebar}
              >
                <FaBoxesStacked className="mr-2" />
                Toplu Ürün Ekle
              </NavLink>
            </div>
          </div>
        </div>

        <div className="mb-3">
          <NavLink
            to="/support"
            className={({ isActive }) =>
              `flex items-center p-2 rounded-lg transition-colors ${isActive ? 'bg-[#7b2cbf] text-white' : 'text-gray-700 hover:bg-gray-100'}`
            }
            onClick={toggleSidebar}
          >
            <FaHeadset className="mr-2" />
            Destek
          </NavLink>
        </div>

        <div className="mb-3">
          <span className="text-gray-700 font-semibold">Hesap Ayarları</span>
          <div className="ml-1 mt-1">
            <div className="mb-1">
              <NavLink
                to="/my-account/account-details"
                className={({ isActive }) =>
                  `flex items-center p-2 rounded-lg transition-colors ${isActive ? 'bg-[#7b2cbf] text-white' : 'text-gray-700 hover:bg-gray-100'}`
                }
                onClick={toggleSidebar}
              >
                <FaUser className="mr-2" />
                Hesap Detayları
              </NavLink>
            </div>
            <div className="mb-1">
              <NavLink
                to="/my-account/subscription"
                className={({ isActive }) =>
                  `flex items-center p-2 rounded-lg transition-colors ${isActive ? 'bg-[#7b2cbf] text-white' : 'text-gray-700 hover:bg-gray-100'}`
                }
                onClick={toggleSidebar}
              >
                <FaArrowsRotate className="mr-2" />
                Abonelik Yönetimi
              </NavLink>
            </div>
            <div className="mb-1">
              <NavLink
                to="/variation-profiles"
                className={({ isActive }) =>
                  `flex items-center p-2 rounded-lg transition-colors ${isActive ? 'bg-[#7b2cbf] text-white' : 'text-gray-700 hover:bg-gray-100'}`
                }
                onClick={toggleSidebar}
              >
                <FaFilter className="mr-2" />
                Varyasyon Profilleri
              </NavLink>
            </div>
            <div className="mb-1">
              <NavLink
                to="/my-account/description-generator"
                className={({ isActive }) =>
                  `flex items-center p-2 rounded-lg transition-colors ${isActive ? 'bg-[#7b2cbf] text-white' : 'text-gray-700 hover:bg-gray-100'}`
                }
                onClick={toggleSidebar}
              >
                <FaFile className="mr-2" />
                Açıklama Oluşturucu
              </NavLink>
            </div>
            <div className="mb-1">
              <NavLink
                to="/my-account/stores"
                className={({ isActive }) =>
                  `flex items-center p-2 rounded-lg transition-colors ${isActive ? 'bg-[#7b2cbf] text-white' : 'text-gray-700 hover:bg-gray-100'}`
                }
                onClick={toggleSidebar}
              >
                <FaStore className="mr-2" />
                Mağazalarım
              </NavLink>
            </div>
          </div>
        </div>
      </nav>

      {/* Footer with User Info */}
      <div className="mt-auto">
        <div className="p-3 cursor-pointer flex items-center justify-between bg-gray-100 rounded-lg" onClick={toggleUserMenu}>
          <div>
            <h4 className="text-sm font-bold">{`${userData.first_name} ${userData.last_name}`}</h4>
            <p className="text-xs text-gray-500">{userData.email}</p>
          </div>
          {isUserMenuOpen ? <FiChevronUp /> : <FiChevronDown />}
        </div>

        {isUserMenuOpen && (
          <div className="mt-1">
            <NavLink
              to="/my-account/account-details"
              className="block px-3 py-2 text-gray-700 hover:bg-gray-100 rounded-lg"
              onClick={toggleSidebar}
            >
              <FaUser className="inline-block mr-2" />
              Hesap Detayları
            </NavLink>
            <button
              onClick={handleLogout}
              className="w-full text-left px-3 py-2 text-gray-700 hover:bg-gray-100 rounded-lg"
            >
              <FaSignOutAlt className="inline-block mr-2" />
              Oturumu Kapat
            </button>
          </div>
        )}
        <div className="p-3 mt-auto text-gray-500">
          <p>Canvas Helper v1.0</p>
        </div>
      </div>
    </div>
  );
};

export default MySidebar;
