import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import StoreLimitModal from '../../components/StoreLimitModal';
import StoreDeleteModal from '../../components/StoreDeleteModal';
import Sections from '../../components/Sections';
import ShippingProfiles from '../../components/ShippingProfiles.js';

const DefaultStoreImage = 'https://app.canvashelper.com/logo192.png';

function Stores({ setSelectedStore }) {
    const [stores, setStores] = useState([]);
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [storeToDelete, setStoreToDelete] = useState(null);
    const [limitExceeded, setLimitExceeded] = useState(false);
    const [selectedStoreId, setSelectedStoreId] = useState(null);
    const [activeTab, setActiveTab] = useState('sections'); // Aktif tabı kontrol etmek için state
    const [storeLogos, setStoreLogos] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        fetchStores();
    }, []);

    const fetchStores = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem('token');
            const response = await axios.get('https://etsyhelper.onrender.com/stores/list', {
                headers: { Authorization: `Bearer ${token}` },
            });
            setStores(response.data);
            if (response.data.length > 0) {
                setSelectedStore(response.data[0].id);
            }
        } catch (error) {
            console.error('Error fetching stores:', error);
            toast.error('Failed to fetch stores.');
        } finally {
            setLoading(false);
        }
    };

    const toggleAccordion = (storeId) => {
        setSelectedStoreId((prevId) => (prevId === storeId ? null : storeId));
    };

    const handleStoreSubmit = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('token');
            const storeData = { name };

            const response = await axios.post('https://etsyhelper.onrender.com/stores/create', storeData, {
                headers: { Authorization: `Bearer ${token}` },
            });

            if (response.data.store_id) {
                toast.success('Store created successfully!');
                fetchStores();
                setSelectedStore(response.data.store_id);
                setName('');
            }
        } catch (error) {
            if (error.response && error.response.status === 403) {
                setLimitExceeded(true);
                setShowModal(true);
            } else {
                console.error('Error creating store:', error);
                toast.error('Failed to create store.');
            }
        }
    };

    // PKCE için code_challenge oluşturma
    const generateCodeChallenge = async (verifier) => {
        const encoder = new TextEncoder();
        const data = encoder.encode(verifier);
        const digest = await crypto.subtle.digest('SHA-256', data);
        return btoa(String.fromCharCode(...new Uint8Array(digest)))
            .replace(/\+/g, '-')
            .replace(/\//g, '_')
            .replace(/=+$/, '');
    };

    const handleReauthorize = async (storeId) => {
        const clientId = process.env.REACT_APP_ETSY_CLIENT_ID;
        const redirectUri = process.env.REACT_APP_ETSY_REDIRECT_URI;
        const state = Math.random().toString(36).substring(2);
        const scope = 'shops_r shops_w transactions_r listings_w';
    
        const codeVerifier = Math.random().toString(36).substring(2); // Code Verifier oluşturma
        const codeChallenge = await generateCodeChallenge(codeVerifier); // Code Challenge oluşturma
        localStorage.setItem('codeVerifier', codeVerifier); // Code Verifier'ı sakla
        localStorage.setItem('oauthState', state); // State parametresini sakla
        localStorage.setItem('storeId', storeId); // storeId'yi localStorage'da saklıyoruz
        const authUrl = `https://www.etsy.com/oauth/connect?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}&code_challenge=${codeChallenge}&code_challenge_method=S256`;
        window.location.href = authUrl; // Kullanıcıyı Etsy yetkilendirme sayfasına yönlendiriyoruz
    };

    const confirmRemove = (storeId) => {
        setStoreToDelete(storeId);
        setShowModal(true);
    };

    const handleRemove = async () => {
        try {
            const token = localStorage.getItem('token');
            await axios.delete(`https://etsyhelper.onrender.com/stores/delete/${storeToDelete}`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            toast.success('Store removed successfully!');
            fetchStores();
            setShowModal(false);
            setStoreToDelete(null);
        } catch (error) {
            console.error('Error removing store:', error);
            toast.error('Failed to remove store.');
            setShowModal(false);
            setStoreToDelete(null);
        }
    };

    const handleUpgradePlan = () => {
        navigate('/my-account/subscription');
        setShowModal(false);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setStoreToDelete(null);
    };

    const handleCloseLimitModal = () => {
        setShowModal(false);
        setLimitExceeded(false);
    };

    const fetchShopInfo = async (storeId) => {
        const token = localStorage.getItem('token');
        if (!token) {
            console.error('JWT token bulunamadı');
            return;
        }
    
        try {
            setLoading(true);
            const response = await fetch(`https://etsyhelper.onrender.com/fetch-shop-info?store_id=${storeId}`, {
                method: 'GET',
                headers: { 'Authorization': `Bearer ${token}` },
            });
    
            const data = await response.json();
            if (response.ok) {
                toast.success('Mağaza bilgileri başarıyla alındı.');
                console.log('Mağaza bilgileri:', data);
    
                // Sadece user_id ve shop_id ile çalışıyorsanız diğer işlemler kaldırıldı
                console.log(`User ID: ${data.user_id}, Shop ID: ${data.shop_id}`);
    
                fetchStores();  // Diğer mağaza bilgilerini güncelleme fonksiyonu
            } else {
                console.error('Mağaza bilgisi alınamadı:', data.message);
                toast.error('Mağaza bilgisi alınamadı.');
            }
        } catch (error) {
            console.error('İstek sırasında hata oluştu:', error);
            toast.error('Mağaza bilgileri alınamadı.');
        } finally {
            setLoading(false);
        }
    };
    
    

    const renderTabsContent = (storeId) => {
        if (activeTab === 'sections') {
            return <Sections storeId={storeId} />;
        } else if (activeTab === 'shopInfo') {
            return <div>Store details and shop info for store {storeId}.</div>;
        } else if (activeTab === 'shippingProfiles') {
            return <ShippingProfiles storeId={storeId} />;  // ShippingProfiles bileşenini burada render ediyoruz
        }
    };
    

    return (
        <div className="container mx-auto mt-10">
            <h1 className="text-3xl font-bold text-center mb-10">Manage Your Stores</h1>

            <form onSubmit={handleStoreSubmit} className="mb-8">
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Store Name</label>
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                        className="mt-1 block w-full pl-3 pr-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                </div>
                <button
                    type="submit"
                    className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                >
                    Add Store
                </button>
            </form>

            <h2 className="text-2xl font-bold mb-4">Your Stores</h2>
            {loading ? (
                <p>Loading stores...</p>
            ) : (
                <ul className="list-disc pl-5">
                    {stores.length === 0 ? (
                        <p>No stores found.</p>
                    ) : (
                        stores.map((store) => (
                            <li key={store.id} className="bg-white p-4 rounded-lg shadow-md mb-4">
                                <div
                                    className="flex justify-between items-center cursor-pointer"
                                    onClick={() => toggleAccordion(store.id)}
                                >
                                    <div>
                                        <p className="text-lg font-semibold">{store.shop_name || "Store Name Pending"}</p>
                                        <p className="text-sm text-gray-500">
                                            {store.shop_id ? `Etsy Shop ID: ${store.shop_id}` : "Mağaza Bilgileri Alınmadı"}
                                        </p>
                                    </div>
                                    <div className="w-24 h-24">
                                        <a href={store.shop_url || '#'} target="_blank" rel="noopener noreferrer">
                                        <img
                                            src={store.shop_logo_url ? store.shop_logo_url : DefaultStoreImage}
                                            alt="Store Logo"
                                            className="w-full h-full object-cover rounded-md"
                                        />
                                        </a>
                                    </div>
                                </div>

                                {selectedStoreId === store.id && (
                                    <div className="w-full mt-4">
                                        <div className="border-b border-gray-200">
                                            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                                                <button
                                                    className={`${
                                                        activeTab === 'sections'
                                                            ? 'border-indigo-500 text-indigo-600'
                                                            : 'border-transparent text-gray-500'
                                                    } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
                                                    onClick={() => setActiveTab('sections')}
                                                >
                                                    Sections
                                                </button>
                                                <button
                                                    className={`${
                                                        activeTab === 'shopInfo'
                                                            ? 'border-indigo-500 text-indigo-600'
                                                            : 'border-transparent text-gray-500'
                                                    } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
                                                    onClick={() => setActiveTab('shopInfo')}
                                                >
                                                    Shop Info
                                                </button>
                                                <button
                                                    className={`${
                                                        activeTab === 'shippingProfiles'
                                                            ? 'border-indigo-500 text-indigo-600'
                                                            : 'border-transparent text-gray-500'
                                                    } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
                                                    onClick={() => setActiveTab('shippingProfiles')}
                                                >
                                                    Shipping Profiles
                                                </button>
                                            </nav>
                                        </div>
                                        <div className="mt-4 p-4">
                                            {renderTabsContent(store.id)}
                                        </div>

                                        <div className="mt-4 flex space-x-4">
                                            <button
                                                className="bg-yellow-500 text-white py-2 px-4 rounded-md hover:bg-yellow-600"
                                                onClick={() => handleReauthorize(store.id)} 
                                            >
                                                Reauthorize Store
                                            </button>
                                            <button
                                                className="bg-green-500 text-white py-2 px-4 rounded-md hover:bg-green-600"
                                                onClick={() => fetchShopInfo(store.id)}
                                            >
                                                Fetch Store Info
                                            </button>
                                            <button
                                                onClick={() => confirmRemove(store.id)}
                                                className="bg-red-500 text-white py-2 px-4 rounded-md hover:bg-red-600"
                                            >
                                                Remove Store
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </li>
                        ))
                    )}
                </ul>
            )}

            <StoreLimitModal
                isVisible={limitExceeded}
                onClose={handleCloseLimitModal}
                onUpgrade={handleUpgradePlan}
            />

            <StoreDeleteModal
                isVisible={storeToDelete !== null}
                onClose={handleCloseModal}
                onConfirm={handleRemove}
            />
        </div>
    );
}

export default Stores;


