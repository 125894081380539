import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const AuthCallback = () => {
    const navigate = useNavigate();   

    useEffect(() => {
        const getToken = async (code) => {
            const clientId = process.env.REACT_APP_ETSY_CLIENT_ID;
            const redirectUri = process.env.REACT_APP_ETSY_REDIRECT_URI;
            const codeVerifier = localStorage.getItem('codeVerifier');  // Ensure this is the same verifier used before

            const query = new URLSearchParams(window.location.search);
            const returnedState = query.get('state');
            const savedState = localStorage.getItem('oauthState');
            const token = localStorage.getItem('token');
            const storeId = localStorage.getItem('storeId');

            
            // State kontrolü
            if (returnedState !== savedState) {
                toast.error('State parametresi eşleşmedi.');
                return;
            }

            try {
                const response = await fetch('https://etsyhelper.onrender.com/exchange-token', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        client_id: clientId,
                        redirect_uri: redirectUri,
                        code: code,
                        code_verifier: codeVerifier,
                        store_id: storeId  // storeId'yi backend'e gönderiyoruz
                    }),
                });
        
                const data = await response.json();
                if (response.ok) {
                    console.log('Access Token:', data.access_token);
                    localStorage.setItem('etsyAccessToken', data.access_token);  // Access token'ı kaydedin
                    navigate('/my-account/stores');
                    
                    // Access token alındıktan sonra mağaza bilgilerini alıyoruz
                    
                } else {
                    console.error('Error exchanging token:', data);
                    toast.error('Token alma işlemi sırasında hata oluştu.');
                }
            } 
            catch (error) {
                console.error('Error:', error);
                toast.error('Token alma işlemi sırasında hata oluştu.');
            }
        };

        // URL'deki "code" parametresini alıyoruz
        const query = new URLSearchParams(window.location.search);
        const code = query.get('code');
        if (code) {
            getToken(code);
        } else {
            toast.error('Yetkilendirme kodu bulunamadı.');
        }
    }, [navigate]);

    return (
        <div>
            <h2>Yetkilendirme işlemi tamamlanıyor...</h2>
        </div>
    );
};

export default AuthCallback;

