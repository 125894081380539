import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import axios from 'axios';
import AllProducts from './pages/AllProducts';
import MyAccount from './pages/MyAccount/MyAccount';
import ProductEdit from './pages/ProductEdit';
import Login from './pages/Login';
import Register from './pages/Register';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import { AuthProvider, useAuth } from './context/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import BulkUpload from './pages/BulkUpload';
import AccountDetails from './pages/MyAccount/AccountDetails';
import Subscription from './pages/MyAccount/Subscription';
import VariationProfiles from './pages/VariationProfiles';
import Stores from './pages/MyAccount/Stores';
import DescriptionGenerator from './pages/MyAccount/DescriptionGenerator';
import Success from './pages/Success';
import OAuthCallback from './pages/OAuthCallback';
import Support from './pages/Support';
import PreparedListings from './pages/PreparedListings';
import { quantum } from 'ldrs';
import './App.css'; // Global CSS

quantum.register(); // QuantumSpinner'ı kaydetme


function AppContent() {
  const { isAuthenticated, loading } = useAuth();
  const [notification, setNotification] = useState('');
  const [sidebarOpen, setSidebarOpen] = useState(false); // Sidebar state'i eklendi

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <l-quantum size="45" speed="1.75" color="black"></l-quantum>
      </div>
    );
  }

  return (
    isAuthenticated ? (
      <div className={`flex ${sidebarOpen ? 'ml-64' : ''}`}> {/* Sidebar açıkken margin ekle */}
        <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={() => setSidebarOpen(!sidebarOpen)} /> {/* Sidebar'a state'i geçiyoruz */}
        <div className="flex-1 flex flex-col">
          <Header toggleSidebar={() => setSidebarOpen(!sidebarOpen)} /> {/* Header'a toggleSidebar fonksiyonunu geçiyoruz */}
          <div className="container mx-auto mt-4">
            {notification && (
              <div className={`alert ${notification.includes('successful') ? 'alert-success' : 'alert-error'}`}>
                {notification}
              </div>
            )}
          </div>
          <div className="container mx-auto mt-4 flex-1">
            <Routes>
              <Route path="/" element={<PrivateRoute><BulkUpload /></PrivateRoute>} />
              <Route path="/all-products" element={<PrivateRoute><AllProducts /></PrivateRoute>} />
              <Route path="/my-account/*" element={<PrivateRoute><MyAccount /></PrivateRoute>} />
              <Route path="/my-account/details" element={<PrivateRoute><AccountDetails /></PrivateRoute>} />
              <Route path="/pages/edit-product/:id" element={<PrivateRoute><ProductEdit /></PrivateRoute>} />
              <Route path="/variation-profiles" element={<PrivateRoute><VariationProfiles /></PrivateRoute>} />
              <Route path="/my-account/stores" element={<PrivateRoute><Stores /></PrivateRoute>} />
              <Route path="/my-account/description-generator" element={<PrivateRoute><DescriptionGenerator /></PrivateRoute>} />
              <Route path="/bulk-upload" element={<PrivateRoute><BulkUpload /></PrivateRoute>} />
              <Route path="/my-account/subscription" element={<PrivateRoute><Subscription /></PrivateRoute>} />
              <Route path="/success" element={<Success />} />
              <Route path="/oauth/callback" element={<OAuthCallback />} />
              <Route path="/support" element={<Support />} />
              <Route path="/prepared-listings" element={<PrivateRoute><PreparedListings /></PrivateRoute>} />
              <Route path="/login" element={<Navigate to="/" />} />
              <Route path="/register" element={<Navigate to="/" />} />
            </Routes>
          </div>
        </div>
      </div>
    ) : (
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    )
  );
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
}

export default App;
