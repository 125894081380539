import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

function Sections({ storeId }) {
    const [sections, setSections] = useState([]);
    const [loading, setLoading] = useState(false);
    const [keywords, setKeywords] = useState({}); // Section'lar için anahtar kelimeler

    // Veritabanındaki mevcut bölümleri çek
    useEffect(() => {
        if (storeId) {
            fetchDatabaseSections();
            fetchKeywords();
        }
    }, [storeId]);  // storeId her değiştiğinde tetiklenir

    const fetchDatabaseSections = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`https://etsyhelper.onrender.com/stores/${storeId}/sections-from-db`);
            setSections(response.data.sections);

            // Anahtar kelimeleri set et
            const keywordsObject = response.data.sections.reduce((acc, section) => {
                acc[section.shop_section_id] = section.keywords || '';
                return acc;
            }, {});
            setKeywords(keywordsObject);
        } catch (error) {
            toast.error('Failed to fetch sections from database.');
        } finally {
            setLoading(false);
        }
    };

    // Etsy'den verileri senkronize et ve veritabanına kaydet
    const syncSectionsFromEtsy = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem('etsyAccessToken');
            const response = await axios.get(`https://etsyhelper.onrender.com/stores/${storeId}/sections`, {
                headers: { etsyAccessToken: token },
            });

            setSections(response.data.sections);
            toast.success('Sections synchronized successfully.');
        } catch (error) {
            toast.error('Failed to sync sections from Etsy.');
        } finally {
            setLoading(false);
        }
    };

    // Anahtar kelimeleri değiştir
    const handleKeywordChange = (sectionId, newKeywords) => {
        setKeywords((prevKeywords) => ({
            ...prevKeywords,
            [sectionId]: newKeywords,
        }));
    };

    // Anahtar kelimeleri veritabanına kaydet
    const saveKeywords = async () => {
        try {
            const token = localStorage.getItem('token');  // JWT token'ı localStorage'dan alıyoruz
            if (!token) {
                throw new Error('No token found');  // Token yoksa hata fırlatıyoruz
            }

            // localStorage'dan almak yerine storeId prop'tan alıyoruz
            await axios.post(`https://etsyhelper.onrender.com/stores/save-keywords`, {
                store_id: storeId,  // prop'tan gelen storeId'yi gönderiyoruz
                sections: sections.map((section) => ({
                    shop_section_id: section.shop_section_id,
                    keywords: keywords[section.shop_section_id],
                })),
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,  // Authorization başlığını ekliyoruz
                }
            });

            toast.success('Keywords saved successfully.');
        } catch (error) {
            console.error('Error saving keywords:', error);
            toast.error('Failed to save keywords.');
        }
    };

    // Anahtar kelimeleri veritabanından al
    const fetchKeywords = async () => {
        try {
            const token = localStorage.getItem('token');  // JWT token'ı alıyoruz
            if (!token) {
                throw new Error('No token found');  // Token yoksa hata fırlatıyoruz
            }

            const response = await axios.get(`https://etsyhelper.onrender.com/stores/get-keywords/${storeId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,  // Authorization başlığını ekliyoruz
                }
            });

            // Anahtar kelimeleri state'e set et
            const keywordsObject = response.data.sections.reduce((acc, section) => {
                acc[section.shop_section_id] = section.keywords || '';
                return acc;
            }, {});
            setKeywords(keywordsObject);

            toast.success('Keywords fetched successfully.');
        } catch (error) {
            console.error('Error fetching keywords:', error);
            toast.error('Failed to fetch keywords.');
        }
    };

    if (loading) {
        return <p>Loading sections...</p>;
    }

    return (
        <div>
            <h3 className="text-xl font-bold mb-4">Store Sections</h3>
            <button
                className="bg-blue-500 text-white py-2 px-4 rounded-md mb-4 hover:bg-blue-600"
                onClick={syncSectionsFromEtsy}
            >
                Sync with Etsy
            </button>

            <ul>
                {sections.map((section) => (
                    <li key={section.shop_section_id} className="mb-2 border p-2 bg-white">
                        <div className="flex justify-between">
                            <span>{section.title}</span>
                            <input
                                type="text"
                                placeholder="Enter keywords for this section"
                                value={keywords[section.shop_section_id] || ''}
                                onChange={(e) => handleKeywordChange(section.shop_section_id, e.target.value)}
                                className="ml-4 p-2 border rounded-md"
                            />
                        </div>
                    </li>
                ))}
            </ul>

            <button
                className="bg-green-500 text-white py-2 px-4 rounded-md mt-4 hover:bg-green-600"
                onClick={saveKeywords}
            >
                Save Keywords
            </button>
        </div>
    );
}

export default Sections;
